export const filterPuc = (item, text) => {
    return (
        item.codigo.toLowerCase().includes(text.toLowerCase()) ||
        item.descripcion.toLowerCase().includes(text.toLowerCase())
    )
}

export const filterCodigosActividadEconomica = (item, text) => {
    const codigo =
        typeof item.codigo !== 'string' ? item.codigo.toString() : item.codigo
    return (
        codigo.toLowerCase().includes(text.toLowerCase()) ||
        item.descripcion.toLowerCase().includes(text.toLowerCase())
    )
}

export const filterTerceros = (item, text) => {
    return (
        item.nombre1.toLowerCase().includes(text.toLowerCase()) ||
        item.razon_social.toLowerCase().includes(text.toLowerCase()) ||
        item.apellido1.toLowerCase().includes(text.toLowerCase()) ||
        item.n_documento.toLowerCase().includes(text.toLowerCase())
    )
}
