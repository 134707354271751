<template>
    <div class="parent">
        <b-form-input
            :ref="reference"
            :id="id"
            :placeholder="placeholder"
            v-model="search"
            @input="filter"
            :state="state"
            @mousedown.left="
                itemsCopy.length === 0 && generateItemsCopy(),
                    (showOptions = openOnFocus)
            "
            autocomplete="null"
            :disabled="disabled"
            @keyup="($evt) => $emit('keyup', $evt)"
            @focus="
                ($evt) => {
                    $emit('focus', $evt)
                    this.showOptions = true
                }
            "
            @blur="
                ($evt) => {
                    $emit('blur', $evt)
                    this.showOptions = false
                }
            "
        />
        <div
            class="selected-container"
            v-if="multiple && selectedItems.length > 0"
        >
            <span
                :class="`badge bg-light-success ${
                    Theme === 'dark' && 'text-gray'
                }`"
                v-if="resumeSelected"
                @mousedown.left="resumeSelected = !resumeSelected"
            >
                <span v-if="returnObject"
                    >{{ selectedItems[0][itemText]
                    }}{{
                        selectedItems.length - 1 !== 0
                            ? `, +${selectedItems.length - 1}`
                            : ''
                    }}</span
                >
                <span v-else
                    >{{
                        items.find((i) => i[itemValue] === selectedItems[0])[
                            itemText
                        ]
                    }}
                    {{
                        selectedItems.length - 1 !== 0
                            ? `, +${selectedItems.length - 1}`
                            : ''
                    }}</span
                >
            </span>
            <span
                :class="`badge bg-light-success ${
                    Theme === 'dark' && 'text-gray'
                }`"
                v-else
                @mousedown.left="resumeSelected = !resumeSelected"
            >
                <span v-for="(s, i) in selectedItems" :key="`s-${i}`">
                    <span v-if="returnObject"
                        >{{ s[itemText]
                        }}{{ i === selectedItems.length - 1 ? '' : ', ' }}</span
                    >
                    <span v-else
                        >{{ items.find((i) => i[itemValue] === s)[itemText]
                        }}{{ i === selectedItems.length - 1 ? '' : ', ' }}</span
                    >
                </span>
            </span>
        </div>
        <div class="options-container" v-show="showOptions">
            <div id="option" v-if="!multiple">
                <div
                    class="opt m-1"
                    role="button"
                    v-for="(item, i) in itemsCopy"
                    :id="`popover-${id}-${i}`"
                    :key="i"
                    @mousedown.left="setOption(item)"
                >
                    <!-- :id="`${id}-${i}`" -->
                    <!-- v-b-tooltip.hover.top="item[itemText]" -->
                    <!-- v-b-popover.hover.left="item[itemText]" -->
                    <!-- <span>
            <slot>{{ item[itemText] }}</slot>
          </span> -->
                    <span>
                        <slot name="option" v-bind:item="item">
                            {{ item[itemText] }}
                        </slot>
                    </span>
                    <!-- <b-popover
                        :target="`popover-${id}-${i}`"
                        :content="item[itemText]"
                        triggers="hover"
                    >
                    </b-popover> -->
                    <b-tooltip
                        :target="`popover-${id}-${i}`"
                        triggers="hover"
                        placement="topright"
                    >
                        <!-- {{ itemText }} - {{ item[itemText] }} -->
                        <!-- {{ item[itemText] || customTextField(item) }} -->
                        <span v-if="customTextField">{{
                            customTextField(item)
                        }}</span>
                        <!-- customTextField(item) -->

                        <span v-if="item[itemText]">
                            {{ item[itemText] }}
                        </span>
                    </b-tooltip>
                </div>
            </div>
            <div v-if="multiple">
                <div
                    class="p-1 opt d-flex justify-content-between align-items-center"
                    role="button"
                    v-for="(item, i) in itemsCopy"
                    :id="`popover-${id}-${i}`"
                    :key="`m-${i}`"
                    @mousedown.left="setMultipleOption(item)"
                >
                    <!-- :id="`${id}-${i}`" -->
                    <!-- v-b-tooltip.hover.top="item[itemText]" -->
                    <!-- v-b-popover.hover.left="item[itemText]" -->
                    <!-- <span>
            {{ item[itemText] }}
            <feather v-if="checkExist(item)" type="check" class="text-success" size="1rem" />
          </span> -->
                    <span>
                        <slot name="option" v-bind:item="item">
                            {{ item[itemText] }}
                            <feather
                                v-if="checkExist(item)"
                                type="check"
                                class="text-success"
                                size="1rem"
                            />
                        </slot>
                    </span>
                    <!-- <b-popover
                        :target="`popover-${id}-${i}`"
                        :content="item[itemText]"
                        triggers="hover"
                    >
                    </b-popover> -->
                    <b-tooltip
                        :target="`popover-${id}-${i}`"
                        triggers="hover"
                        placement="topright"
                    >
                        <span v-if="customTextField">{{
                            customTextField(item)
                        }}</span>

                        <span v-if="item[itemText]">
                            {{ item[itemText] }}
                        </span>
                    </b-tooltip>
                </div>
            </div>
            <slot name="no-results" v-if="itemsCopy.length === 0">
                <!-- <div
                    class="px-2 option-disabled d-flex justify-content-between"
                > -->
                <div>Sin resultados</div>
                <!-- </div> -->
            </slot>
            <div
                v-if="showCreateButton"
                class="text-white bg-primary p-1 text-center"
                role="button"
                @mousedown.left="createMethod()"
            >
                Crear
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    name: 'JAutocomplete',
    props: {
        placeholder: { type: String, default: 'Buscar' },
        id: { type: String, default: null },
        itemText: { type: String, default: 'text' },
        itemValue: { type: String, default: 'value' },
        items: { type: Array, default: () => [] },
        returnObject: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        value: [String, Array, Number, Object],
        state: { type: Boolean, default: undefined },
        openOnFocus: { type: Boolean, default: false },
        customFilter: { type: Function, default: undefined },
        customTextField: { type: Function, default: undefined },
        disabled: { type: Boolean, default: false },
        loadAsync: { type: Boolean, default: false },
        reference: { type: String, default: 'input' },
        showCreateButton: { type: Boolean, default: false },
        createMethod: { type: Function, default: () => {} },
        noNull: { type: Boolean, default: false }
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    data: () => ({
        search: '',
        searchMultiple: '',
        showOptions: false,
        selectedItem: null,
        selectedItems: [],
        itemsCopy: [],
        resumeSelected: true,
        indexSelector: -1
    }),
    watch: {
        items() {
            // console.log(this.id, this.value)
            this.generateItemsCopy()
            setTimeout(() => {
                this.findData()
            }, 500)
        },
        value(newValue) {
            if (
                newValue !== '' &&
                newValue !== undefined &&
                newValue !== null
            ) {
                setTimeout(() => {
                    this.findData()
                }, 1000)
            } else {
                this.search = ''
            }
        }
    },
    mounted() {
        document.addEventListener('keydown', this.handleUpAndDownArroyKeys)
        document.addEventListener('click', this.handleClickOutside)
    },
    destroyed() {
        document.removeEventListener('click', this.handleClickOutside)
        document.removeEventListener('keydown', this.handleUpAndDownArroyKeys)
    },
    methods: {
        setActiveItem(classHover) {
            document.getElementById(
                `popover-${this.id}-${this.indexSelector}`
            ).style = classHover
        },
        clearItemsHover() {
            this.itemsCopy.forEach((item, index) => {
                if (index !== this.indexSelector) {
                    const el = document.getElementById(
                        `popover-${this.id}-${index}`
                    )
                    if (el) {
                        el.style = ''
                    }
                }
            })
        },
        findData() {
            if (
                this.value !== null &&
                this.value !== undefined &&
                this.value !== ''
            ) {
                if (this.multiple) {
                    this.selectedItems = this.value
                } else {
                    if (this.returnObject && this.items.length > 0) {
                        if (this.noNull && this.value) {
                            this.selectedItem = this.value
                            this.search = this.value[this.itemText]
                        }
                    } else if (!this.returnObject && this.items.length > 0) {
                        const item = this.items.find(
                            (i) => i[this.itemValue] === this.value
                        )

                        // console.log(this.id, this.value, item, 'aquí')
                        this.selectedItem = JSON.parse(JSON.stringify(item))
                        if (this.customTextField) {
                            this.search = this.customTextField(item)
                        } else {
                            // if (this.noNull && this.value) {
                            // this.search = this.value[this.itemText]
                            this.search = item[this.itemText]
                            // }
                        }
                    }
                }
            }
        },
        handleUpAndDownArroyKeys(evt) {
            const activeElementId = document.activeElement.id
            if (activeElementId === this.id) {
                // console.log(activeElementId);
                if (evt.key === 'ArrowDown' || evt.key === 'ArrowUp') {
                    // console.log(evt.key);
                    const valueToAdd = evt.key === 'ArrowDown' ? 1 : -1
                    // console.log(this.indexSelector, this.indexSelector + valueToAdd);
                    const existItem =
                        this.itemsCopy[this.indexSelector + valueToAdd]
                    if (existItem) {
                        this.indexSelector += valueToAdd
                        const classHover =
                            'background-color: rgba(89, 89, 89, 0.1);'
                        this.setActiveItem(classHover)
                        this.clearItemsHover()
                    }
                } else if (evt.key === 'Enter') {
                    // console.log(this.indexSelector);
                    if (this.indexSelector > -1) {
                        const opt = this.itemsCopy[this.indexSelector]
                        if (this.multiple) {
                            this.setMultipleOption(opt)
                            this.search = ''
                        } else {
                            this.setOption(opt)
                        }
                        this.clearItemsHover()
                        // this.indexSelector = -1
                    }
                }
            }
        },
        handleClickOutside(event) {
            // console.log(this.value, this.selectedItem, this.id)
            if (!this.$el.contains(event.target)) {
                this.indexSelector = -1
                this.clearItemsHover()
                if (!this.multiple) {
                    if (this.returnObject) {
                        // console.log(this.selectedItem, this.id)
                        // if (this.noNull && this.value) {
                        // console.log(this.id, this.value, this.selectedItem)
                        if (this.customTextField) {
                            this.search = this.customTextField(
                                this.selectedItem
                            )
                        } else {
                            // this.search = this.selectedItem
                            //     ? this.selectedItem[this.itemText]
                            //     : ''
                            if (
                                this.selectedItem === null &&
                                this.noNull === false
                            ) {
                                this.search = ''
                            } else if (this.selectedItem && this.value) {
                                this.search = this.selectedItem[this.itemText]
                            }
                        }
                        // } else {
                        //     this.search = ''
                        // }
                    } else {
                        if (this.selectedItem) {
                            // const item = this.items.find(i => i[this.itemValue] === this.selectedItem)
                            // this.search = this.selectedItem[this.itemText]
                            if (this.customTextField) {
                                this.search = this.customTextField(
                                    this.selectedItem
                                )
                            } else {
                                if (this.value) {
                                    // this.search = this.value[this.itemText]
                                    this.search =
                                        this.selectedItem[this.itemText]
                                }
                            }
                        }
                    }
                } else {
                    this.search = ''
                }
                this.showOptions = false
            }
        },
        filter(text) {
            if (!this.loadAsync) {
                if (text) {
                    this.showOptions = true
                    const defaultFunction = this.customFilter
                        ? (item) => this.customFilter(item, text)
                        : (item) =>
                              item[this.itemText]
                                  .toLowerCase()
                                  .includes(text.toLowerCase())
                    this.itemsCopy = this.items.filter(defaultFunction)
                    this.indexSelector = -1
                    this.clearItemsHover()
                } else {
                    if (!this.multiple) {
                        this.selectedItem = null
                        this.$emit('change', null)
                    }
                    this.showOptions = false
                    this.generateItemsCopy()
                }
            } else {
                this.$emit('text', this.search)
                if (this.search) {
                    this.showOptions = true
                }
            }
        },
        verifyData() {
            if (this.selectedItem) {
                this.search = this.selectedItem[this.itemText]
            }
            this.showOptions = false
        },
        checkExist(opt) {
            if (this.returnObject) {
                const obj = this.selectedItems.find(
                    (i) => i[this.itemValue] === opt[this.itemValue]
                )
                return obj !== undefined
            } else {
                const index = this.selectedItems.indexOf(opt[[this.itemValue]])
                return index !== -1
            }
        },
        generateItemsCopy() {
            this.itemsCopy = JSON.parse(JSON.stringify(this.items))
        },
        setOption(opt) {
            this.selectedItem = JSON.parse(JSON.stringify(opt))
            if (this.customTextField) {
                this.search = this.customTextField(opt)
            } else {
                // console.log(this.value, opt, this.itemText)
                this.search =
                    this.value !== null &&
                    this.value !== undefined &&
                    this.value !== ''
                        ? opt[this.itemText]
                        : ''
            }

            this.showOptions = false
            if (this.returnObject) {
                this.$emit('change', opt)
            } else {
                this.$emit('change', opt[this.itemValue])
            }
        },
        setMultipleOption(opt) {
            if (this.returnObject) {
                const obj = this.selectedItems.find(
                    (i) => i[this.itemValue] === opt[this.itemValue]
                )
                if (obj) {
                    const index = this.selectedItems.indexOf(obj)
                    this.selectedItems.splice(index, 1)
                } else {
                    this.selectedItems.push(opt)
                }
            } else {
                const index = this.selectedItems.indexOf(opt[this.itemValue])
                if (index !== -1) {
                    this.selectedItems.splice(index, 1)
                } else {
                    this.selectedItems.push(opt[this.itemValue])
                }
            }
            this.showOptions = false
            this.$emit('change', this.selectedItems)
        }
    },
    computed: {
        ...mapState(['Theme'])
    }
}
</script>
<style>
.parent {
    position: relative;
    width: 100%;
}
.options-container {
    width: 100%;
    min-height: 1rem;
    max-height: 15rem;
    padding: 0.2rem;
    background-color: #fff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    margin-top: 2px;
    border-radius: 5px;
    position: absolute;
    overflow-y: scroll;
    z-index: 200;
}

.selected-container {
    position: absolute;
    width: 100%;
    height: auto;
}

.opt {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
